import { Route, Routes, useNavigate } from 'react-router-dom';
import routeList from './RouteList';
import ProtectedRoute from './ProtectedRoute';
import { useEffect, useState } from 'react';
import SuspenseWrapper from '../component/SuspenseWrapper/SuspenseWrapper';

function RouteComp() {
    const [token, setToken] = useState(localStorage.getItem('token') || '');
    const navigate = useNavigate();
    const frontend_base_url = process.env.REACT_APP_FRONT_APP_BASE_URL;

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        const role = localStorage.getItem('role');
        if (storedToken) {
            if (window.location.href.split(frontend_base_url)[1].split("/")[2] === "login") {
                if (role === "Admin") {
                    navigate("/admin/dashboard",)
                }
                else {
                    if (localStorage.getItem('on_boarding') === "true") {
                        navigate("/dashboard",)
                    }
                    else {
                        navigate("/onboarding",)
                    }
                }
            }

            if (window.location.href.split(frontend_base_url)[1].split("/").includes("app")) {

                if (role === "Admin") {
                    navigate(`/admin/dashboard`,)
                }
                else {
                    if (localStorage.getItem('on_boarding') === "true") {
                        navigate("/dashboard",)
                    }
                    else {
                        navigate(`/onboarding`,)
                    }
                }
            }
        }
    }, []);


    return (<Routes>

        {routeList.map((item, index) => {
            return (
                <Route
                    key={index}
                    path={item.path}
                    exact={item.exact}
                    element={
                        item.protected
                            ?
                            <ProtectedRoute component={item.element} menuTitle={item.menuTitle} showBackButton={item.menuTitle === "Dashboard" ? false : true} />
                            :
                            <SuspenseWrapper>
                                {
                                    item.element
                                }
                            </SuspenseWrapper>
                    }
                >
                    {item.children &&
                        item.children.map((child, i) => {
                            return (
                                <Route
                                    key={i}
                                    path={child.path}
                                    element={child.element}
                                />
                            );
                        })}
                </Route>
            );
        })}



    </Routes >)
}

export default RouteComp;