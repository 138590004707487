import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Login
const Login = lazy(() => import('../container/Login/Login'));
// OnBoarding
const OnBoarding = lazy(() => import('../container/OnBoarding/OnBoarding'));

// PayRoll
const PayRollDashboard = lazy(() => import('../container/PayRoll/User/Tabs'));
const PayRollSummary = lazy(() => import('../container/PayRoll/User/PayrollSummary/PayrollSummary'));
// Employee
const Employee = lazy(() => import('../container/Employee/Employee'));

// Recruitment
const RecruitmentDashboard = lazy(() => import('../container/Recruitment/User/Dashboard/Dashboard'));
const ReferralOpening = lazy(() => import('../container/Recruitment/User/ReferralOpening/ReferralOpening'));
const InternalJobOpening = lazy(() => import('../container/Recruitment/User/InternalJobOpening/InternalJobOpening'));
const ExternalRecruitment = lazy(() => import('../container/ExternalRecruitment/ExternalRecruitment'));
const ExternalRecruitmentLogin = lazy(() => import('../container/ExternalRecruitment/ExternalRecruitmentLogin/ExternalRecruitmentLogin'));

const Org = lazy(() => import('../container/Org/Org'));
const Requisition = lazy(() => import('../container/Recruitment/User/Requisition/Requisition '));
const ViewFeedBack = lazy(() => import('../container/Job/FeedBack/ViewFeedBack/ViewFeedBack'));
const MyTeam = lazy(() => import('../container/Attendance/User/ReportingManager/MyTeam/MyTeam'));
// Attendance
// const Attendance = lazy(()=> import()) '../container/Attendance/User/Tabs/Tabs';
const AttendanceAdminDashBoard = lazy(() => import('../container/Attendance/Admin/Tabs/Tabs'));
const UserLogsFullView = lazy(() => import('../container/Attendance/User/Employee/LogsFullView/LogsFullView'));
const ManagerLogsFullView = lazy(() => import('../container/Attendance/User/ReportingManager/LogsFullView/LogsFullView'));
const ManagerDetailFullView = lazy(() => import('../container/Attendance/User/ReportingManager/DetailFullView/DetailFullView'));
const PolicyForm = lazy(() => import('../container/Attendance/Admin/PolicyForm/PolicyForm'));
const AttendancePolicyFullView = lazy(() => import('../container/Attendance/Admin/AttendancePolicyFullView/AttendancePolicyFullView'));
const AttendanceReportFullView = lazy(() => import('../container/Attendance/Admin/AttendanceReportFullView/AttendanceReportFullView'));
// Leave
const MyTeamLeaveFullView = lazy(() => import('../container/Leave/User/MyTeam/MyTeam'));
const UserDashboard = lazy(() => import('../container/Leave/User/Tabs/Tabs'));
const LeaveAdminDashboard = lazy(() => import('../container/Leave/Admin/Dashboard/Dashboard'));
const LeavePolicyFullView = lazy(() => import('../container/Leave/Admin/LeavePolicyFullView/LeavePolicyFullView'));
const LeaveReportFullView = lazy(() => import('../container/Leave/Admin/LeaveReportFullView/LeaveReportFullView'));
const LeaveTypeFullView = lazy(() => import('../container/Leave/Admin/LeaveTypeFullView/LeaveTypeFullView'));
const EmployeeList = lazy(() => import('../container/Employee/EmployeeList/EmployeeList'));
const ChangePassword = lazy(() => import('../container/ChangePassword/ChangePassword'));
const EmployeeLeavesFullView = lazy(() => import('../container/Leave/Admin/EmployeeLeavesFullView/EmployeeLeavesFullView'));
const LeaveTypeForm = lazy(() => import('../container/Leave/Admin/LeaveTypeForm/LeaveTypeForm'));
const EditPersonalDetails = lazy(() => import('../container/UserProfile/EditPersonalDetails/EditPersonalDetails'));
const EditEmployeeDetails = lazy(() => import('../container/UserProfile/EditEmployeeDetails/EditEmployeeDetails'));
const HolidayForm = lazy(() => import('../container/Leave/Admin/HolidayForm/HolidayForm'));
const DocumentsDashboard = lazy(() => import('../container/Documents/Dashboard/DocumentsDashboard'));
const HolidayFullView = lazy(() => import('../container/Leave/Admin/HolidayFullView/HolidayFullView'));
const DocumentsHrLetterForm = lazy(() => import('../container/Documents/DocumentsHrLetterForm/DocumentsHrLetterForm'));
const DocumentPolicyForm = lazy(() => import('../container/Documents/DocumentPolicyForm/DocumentPolicyForm'));
const AddOrEditAdminMobile = lazy(() => import('../container/Org/AddOrEditAdminMobile/AddOrEditAdminMobile'));
const AddOrEditOrgMobile = lazy(() => import('../container/Org/AddOrEditOrgMobile/AddOrEditOrgMobile'));
const PayRollDashBoard = lazy(() => import('../container/PayRoll/Admin/Dashboard/Dashboard'));
const AdminPayRollProcessing = lazy(() => import('../container/PayRoll/Admin/PayRollProcessing/PayRollProcessing'));
const SalaryConfigurationForm = lazy(() => import('../container/PayRoll/Admin/SalaryConfigurationForm/SalaryConfigurationForm'));
const HRLetterFullView = lazy(() => import('../container/Documents/HRLetterFullView/HRLetterFullView'));
const PoliciesFullView = lazy(() => import('../container/Documents/PoliciesFullView/PoliciesFullView'));
const PayRollSalaryDetails = lazy(() => import('../container/PayRoll/User/SalaryDetails/SalaryDetails'));
const PayRollTaxDeclarationForm = lazy(() => import('../container/PayRoll/User/TaxDeclarationForm/TaxDeclarationForm'));
const InboxDashboard = lazy(() => import('../container/Inbox/Dashboard/Dashboard'));
const ApplyLeave = lazy(() => import('../container/Leave/User/ApplyLeave/ApplyLeave'));
const LeaveRequestFullView = lazy(() => import('../container/Leave/User/LeaveRequest/LeaveRequestFullView'));
const UserHolidayFullView = lazy(() => import('../container/Leave/User/Holiday/UserHolidayFullView'));
const AdminLeaveTypeFullView = lazy(() => import('../container/Leave/Admin/AdminLeaveTypeFullView/AdminLeaveTypeFullView'));
const Form24Q = lazy(() => import('../container/PayRoll/Admin/Fom24Q/Form24Q'));
// import Form24Setting from '../container/PayRoll/Admin/Form24QSettting/Form24Setting';
const ChallanForm = lazy(() => import('../container/PayRoll/Admin/Fom24Q/ChallanForm/ChallanForm'));
const ForcedDocument = lazy(() => import('../container/ForcedDocument/ForcedDocument'));
const PayrollAdminTabs = lazy(() => import('../container/PayRoll/Admin/PayrollAdminTabs/PayrollAdminTabs'));
const EmployeeAttendancePolicyManagerFullView = lazy(() => import('../container/Attendance/Admin/EmployeeAttendancePolicyManagerFullView/EmployeeAttendancePolicyManagerFullView'));
const LeaveDetail = lazy(() => import('../container/Inbox/Leave/LeaveDetail/LeaveDetail'));
const LoanFullView = lazy(() => import('../container/PayRoll/Admin/LoanFullView/LoanFullView'));
const AdvanceSalaryFullView = lazy(() => import('../container/PayRoll/Admin/AdvanceSalaryFullView/AdvanceSalaryFullView'));
const ReimbursementFullView = lazy(() => import('../container/PayRoll/Admin/ReimbursementFullView/ReimbursementFullView'));

const AdminJobListFullView = lazy(() => import('../container/Recruitment/Admin/Jobs/Jobs'));
const PostRequisitionToJob = lazy(() => import('../container/Recruitment/Admin/RequisitionToJob/PostRequisitionToJob'));
const HoldSalaryFullView = lazy(() => import('../container/PayRoll/Admin/HoldSalaryFullView/HoldSalaryFullView'));

const EmployeeSalaryManager = lazy(() => import('../container/PayRoll/Admin/EmployeeSalaryManager/EmployeeSalaryManager'));

const LeavePolicyManagerFullView = lazy(() => import('../container/Leave/Admin/LeavePolicyManagerFullView/EmployeeLeavesFullView'));
const RequisitionFullView = lazy(() => import('../container/Recruitment/Admin/Requisition/Requisition Fullview'));
const RecruiterFullView = lazy(() => import('../container/Recruitment/Admin/ExternalRecruiters/RecruiterFullView'));
const InterviewFullView = lazy(() => import('../container/Recruitment/User/InterviewScheduleFullView/InterviewFullView'));
const DocsDetail = lazy(() => import('../container/Inbox/Documentation/DocumentationDetails/DocumentationDetails'));

const UserReimbursementFullView = lazy(() => import('../container/PayRoll/User/Reimbursement/Reimbursement'));
const UserReimbursementForm = lazy(() => import('../container/PayRoll/User/ReimbursementForm/ReimbursementForm'));
const UserLoanFullView = lazy(() => import('../container/PayRoll/User/Loan/Loan'));
const UserPayRollSummaryFullView = lazy(() => import('../container/PayRoll/User/PayRollSummaryFullView/PayRollSummaryFullView'));
const OrgAdminFullView = lazy(() => import('../container/Org/AdminsFullView/AdminsFullView'));
const LeavePolicyForm = lazy(() => import('../container/Leave/Admin/LeavePolicyForm/LeavePolicyForm'));
const TaxSummaryFullView = lazy(() => import('../container/PayRoll/User/TaxSummary/TaxSummaryFullView'));
const EmployeeDeclarationDetail = lazy(() => import('../container/PayRoll/Admin/EmployeeDeclaration/EmployeeDeclarationDetail/EmployeeDeclarationDetail'));
const SalaryStructureFullView = lazy(() => import('../container/PayRoll/Admin/SalaryStructureFullView/salaryStructureFullView'));
const EmployeeDeclarationFullView = lazy(() => import('../container/PayRoll/Admin/EmployeeDeclaration/EmployeeDeclarationFullView/EmployeeDeclarationFullView'));
const PayrollSummary = lazy(() => import('../container/PayRoll/Admin/PayrollSummary/PayrollSummary'));
const ResignationDetail = lazy(() => import('../container/Inbox/Resignation/ResignationDetail/ResignationDetail'));
const CompOffForm = lazy(() => import('../container/Leave/Admin/CompOffForm/CompOffForm'));
const CompOffFullView = lazy(() => import('../container/Leave/Admin/CompOffFullView/CompOffFullView'));
const ReferForm = lazy(() => import('../container/Recruitment/ReferForm/ReferForm'));
const FeedBackForm = lazy(() => import('../container/Recruitment/User/FeedBackForm/FeedBackForm'));
const ApplyForm = lazy(() => import('../container/Recruitment/ApplyForm/ApplyForm'));
const RequisitionForm = lazy(() => import('../container/Recruitment/User/RequisitionForm/RequisitionForm'));
const CompanyName = lazy(() => import('../container/CompanyName/CompanyName'));
const AttendanceList = lazy(() => import('../container/Inbox/Attendance/AttendanceList/AttendanceList'));
const OrgConfigDashboard = lazy(() => import('../container/Org/OrgConfig/Dashboard/Dashboard'));
const ReimbursementDetail = lazy(() => import('../container/PayRoll/Admin/ReimbursementDetail/ReimbursementDetail'));
const AttendanceDetail = lazy(() => import('../container/Inbox/Attendance/AttendanceDetail/AttendanceDetail'));
const EditBankDetails = lazy(() => import('../container/UserProfile/EditBankDetails/EditBankDetails'));
const PrivacyPolicy = lazy(() => import('../container/PrivacyPolicy/PrivacyPolicy'));
const PerformanceTabs = lazy(() => import('../container/PerformanceManagement/User/PerformanceTabs/PerformanceTabs'));
const GoalForm = lazy(() => import('../container/PerformanceManagement/User/GoalForm/GoalForm'));
const Goal = lazy(() => import('../container/PerformanceManagement/User/Goal/Goal'));

//tracking

const TrackingTabs = lazy(() => import('../container/Tracking/User/TrackingTabs/TrackingTabs'));
const AdminTrackingTabs = lazy(() => import('../container/Tracking/Admin/TrackingTabs/TrackingTabs'));
const AddMeeting = lazy(() => import('../container/Tracking/AddMeeting/AddMeeting'));
const EditMetting = lazy(() => import('../container/Tracking/EditMeeting/EditMeeting'));
const AttendMeeting = lazy(() => import('../container/Tracking/AttendMeeting/AttendMeeting'));

const Google = lazy(() => import('../container/Google/Google'));
const LmsList = lazy(() => import('../container/lms/user/LmsList/LmsList'));
const LmsInstruction = lazy(() => import('../container/lms/user/LmsInstruction/LmsInstruction'));
const LmsVideo = lazy(() => import('../container/lms/user/LmsVideo/LmsVideo'));
const AdminLmsList = lazy(() => import('../container/lms/admin/LmsList/LmsList'));
const TrainingForm = lazy(() => import('../container/lms/admin/TrainingForm/TrainingForm'));
const LmsQuiz = lazy(() => import('../container/lms/user/LmsQuiz/LmsQuiz'));
const InboxLmsList = lazy(() => import('../container/Inbox/Lms/InboxLmsList'));
const InboxLmsDetail = lazy(() => import('../container/Inbox/Lms/LmsDetails/LmsDetails'));
const LmsDetail = lazy(() => import('../container/lms/admin/LmsDetail/LmsDetail'));
const BulkAttendancePolicy = lazy(() => import('../container/Attendance/Admin/BulkAttendancePolicy/BulkAttendancePolicy'));
const BulkPayrollProcessing = lazy(() => import('../container/PayRoll/Admin/BulkPayrollProcessing/BulkPayrollProcessing'));
const BulkLeavePolicy = lazy(() => import('../container/Leave/Admin/BulkLeavePolicy/BulkLeavePolicy'));
const PaidEmployee = lazy(() => import('../container/PayRoll/Admin/PayrollSummaryInsight/PaidEmployee/PaidEmployee'));
const Feeds = lazy(() => import('../container/Feeds/User/Feeds'));
const AddPost = lazy(() => import('../container/Feeds/User/AddPost/AddPost'));
const IntegarationStatus = lazy(() => import('../container/Attendance/Admin/IntegarationStatus/IntegarationStatus'));
const FeedsTab = lazy(() => import('../container/Feeds/Admin/Tabs/Tabs'));
const EditFeed = lazy(() => import('../container/Feeds/Admin/EditFeed/EditFeed'));
const AddFeedPost = lazy(() => import('../container/Feeds/Admin/Posts/AddPost/AddPost'));
const DisplayPost = lazy(() => import('../container/Feeds/Admin/Posts/DisplayPost/DisplayPost'));
const TimeLine = lazy(() => import('../container/Tracking/User/TimeLine/TimeLine'));
const ReimbursementCategoryDetail = lazy(() => import("../container/PayRoll/Admin/ReimbursementFullView/Setting/CategoryDetail/CategoryDetail"));
const ReimbursementAddCategory = lazy(() => import("../container/PayRoll/Admin/ReimbursementFullView/Setting/AddCategory/AddCategory"))
const ReimbursementEditCategory = lazy(() => import("../container/PayRoll/Admin/ReimbursementFullView/Setting/AddCategory/AddCategory"));
const EmplyeePANPendingList = lazy(() => import("../container/PayRoll/Admin/PayRollProcessing/EmplyeePANPendingList/EmplyeePANPendingList"))
// Dashboard
const Dashboard = lazy(() => import('../container/Dashboard/User/Dashboard'));
const AdminDashboard = lazy(() => import('../container/Dashboard/Admin/Dashboard'));

// Job
const AdminJobList = lazy(() => import('../container/Recruitment/Admin/Tabs/Tabs'));
const PostJob = lazy(() => import('../container/Recruitment/Admin/PostJob/PostJob'));
const AdminJobDetails = lazy(() => import('../container/Recruitment/Admin/AdminJobDetails/AdminJobDetails'));
const UserJobList = lazy(() => import('../container/ExternalRecruitment/User/JobList/UserJobList'));
const ExternalJobDetailsForUser = lazy(() => import('../container/ExternalRecruitment/User/JobDetails/UserJobDetails'));
const JobDetail = lazy(() => import('../container/ExternalRecruitment/JobDetail/JobDetail'));

// UserProfile
const UserProfile = lazy(() => import('../container/UserProfile/UserProfile'));
const Attendance = lazy(() => import('../container/Attendance/User/Tabs/Tabs'));

const routeList = [
  {
    path: '/login',
    exact: true,
    protected: false,
    element: <Login />,
    menuTitle: '',
    children: [],
  },

  {
    path: '/app',
    exact: true,
    protected: false,
    element: <CompanyName />,
    menuTitle: '',
    children: [],
  },
  {
    path: '/privacy-policy',
    exact: true,
    protected: false,
    element: <PrivacyPolicy />,
    menuTitle: '',
    children: [],
  },
  {
    path: '/:companyName/app',
    exact: true,
    protected: false,
    element: <CompanyName />,
    menuTitle: '',
    children: [],
  },
  {
    path: '/:companyName/login',
    exact: true,
    protected: false,
    element: <Login />,
    menuTitle: '',
    children: [],
  },
  {
    path: '/reset-password/:secure_token',
    exact: true,
    protected: false,
    element: <ChangePassword />,
    menuTitle: '',
    children: [],
  },
  {
    path: '/onboarding',
    exact: true,
    protected: false,
    element: <OnBoarding />,
    menuTitle: '',
    children: [],
  },
  {
    path: '/dashboard',
    exact: true,
    protected: true,
    element: <Dashboard />,
    menuTitle: 'Dashboard',
    children: [],
  },
  {
    path: '/forced-document',
    exact: true,
    protected: false,
    element: <ForcedDocument />,
    menuTitle: 'Dashboard',
    children: [],
  },
  {
    path: '/admin/dashboard',
    exact: true,
    protected: true,
    element: <AdminDashboard />,
    menuTitle: 'Dashboard',
    children: [],
  },
  // Leave
  // user
  {
    path: '/leave',
    exact: true,
    protected: true,
    element: <UserDashboard />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/leave/my-team',
    exact: true,
    protected: true,
    element: <MyTeamLeaveFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/leave/holiday-list',
    exact: true,
    protected: true,
    element: <UserHolidayFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  // leave admin
  {
    path: '/admin/leave',
    exact: true,
    protected: true,
    element: <LeaveAdminDashboard />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/:componentId',
    exact: true,
    protected: true,
    element: <LeaveAdminDashboard />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/comp-off/form',
    exact: true,
    protected: true,
    element: <CompOffForm />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-type-form',
    exact: true,
    protected: true,
    element: <LeaveTypeForm />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-policy/form',
    exact: true,
    protected: true,
    element: <LeavePolicyForm />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-policy/form/:id',
    exact: true,
    protected: true,
    element: <LeavePolicyForm />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-type-form/:id',
    exact: true,
    protected: true,
    element: <LeaveTypeForm />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/employee-leaves',
    exact: true,
    protected: true,
    element: <EmployeeLeavesFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  // HolidayForm
  {
    path: '/admin/leave/add-holiday',
    exact: true,
    protected: true,
    element: <HolidayForm />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/holiday',
    exact: true,
    protected: true,
    element: <HolidayFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave_policy_manager',
    exact: true,
    protected: true,
    element: <LeavePolicyManagerFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-policy',
    exact: true,
    protected: true,
    element: <LeavePolicyFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-report',
    exact: true,
    protected: true,
    element: <LeaveReportFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-type',
    exact: true,
    protected: true,
    element: <LeaveTypeFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/leave/leave-full',
    exact: true,
    protected: true,
    element: <AdminLeaveTypeFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/leave/apply-leave',
    exact: true,
    protected: true,
    element: <ApplyLeave />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/leave/leave-request',
    exact: true,
    protected: true,
    element: <LeaveRequestFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/admin/comp-off/full-view',
    exact: true,
    protected: true,
    element: <CompOffFullView />,
    menuTitle: 'Leave',
    children: [],
  },
  {
    path: '/attendance',
    exact: true,
    protected: true,
    element: <Attendance />,
    menuTitle: 'Attendance',
    children: [],
  },
  {
    path: '/attendance/logs',
    exact: true,
    protected: true,
    element: <UserLogsFullView />,
    menuTitle: 'Logs',
    children: [],
  },
  {
    path: '/attendance/my-team',
    exact: true,
    protected: true,
    element: <MyTeam />,
    menuTitle: 'My Team',
    children: [],
  },
  {
    path: '/attendance/my-team/logs',
    exact: true,
    protected: true,
    element: <ManagerLogsFullView />,
    menuTitle: 'Logs',
    children: [],
  },
  {
    path: '/attendance/my-team/details',
    exact: true,
    protected: true,
    element: <ManagerDetailFullView />,
    menuTitle: 'Logs',
    children: [],
  },
  {
    path: '/admin/attendance',
    exact: true,
    protected: true,
    element: <AttendanceAdminDashBoard />,
    menuTitle: 'Attendance',
    children: [],
  },
  {
    path: '/admin/attendance/:componentId',
    exact: true,
    protected: true,
    element: <AttendanceAdminDashBoard />,
    menuTitle: 'Attendance',
    children: [],
  },
  {
    path: '/admin/attendance/attendance-policy',
    exact: true,
    protected: true,
    element: <AttendancePolicyFullView />,
    menuTitle: 'Attendance Policy',
    children: [],
  },
  {
    path: '/admin/attendance/attendance-report',
    exact: true,
    protected: true,
    element: <AttendanceReportFullView />,
    menuTitle: 'Attendance Report',
    children: [],
  },
  {
    path: '/admin/attendance/employee-attendance-policy-manager',
    exact: true,
    protected: true,
    element: <EmployeeAttendancePolicyManagerFullView />,
    menuTitle: 'Attendance Report',
    children: [],
  },
  {
    path: '/admin/attendance/policy-form',
    exact: true,
    protected: true,
    element: <PolicyForm />,
    menuTitle: 'Policy Form',
    children: [],
  },
  {
    path: '/admin/attendance/policy-form/:id/:action?',
    exact: true,
    protected: true,
    element: <PolicyForm />,
    menuTitle: 'Policy Form Edit',
    children: [],
  },
  // BulkAttendancePolicy
  {
    path: '/admin/attendance/bulk-attendance-policy',
    exact: true,
    protected: true,
    element: <BulkAttendancePolicy />,
    menuTitle: 'Bulk Attendance Policy',
    children: [],
  },
  {
    path: '/admin/attendance/integaration-status/:id',
    exact: true,
    protected: true,
    element: <IntegarationStatus />,
    menuTitle: 'Integaration Status',
    children: [],
  },
  {
    path: '/admin/leave/bulk-leave-policy',
    exact: true,
    protected: true,
    element: <BulkLeavePolicy />,
    menuTitle: 'Bulk Leave Policy',
    children: [],
  },
  {
    path: '/admin/payroll/selective-payroll-processing',
    exact: true,
    protected: true,
    element: <BulkPayrollProcessing />,
    menuTitle: 'Bulk Attendance Policy',
    children: [],
  },
  {
    path: '/admin/payroll/insight/paid-employee',
    exact: true,
    protected: true,
    element: <PaidEmployee />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll',
    exact: true,
    protected: true,
    element: <PayRollDashboard />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/tax-summary',
    exact: true,
    protected: true,
    element: <TaxSummaryFullView />,
    menuTitle: 'Payroll',
    children: [],
  },

  {
    path: '/payroll/salary-details',
    exact: true,
    protected: true,
    element: <PayRollSalaryDetails />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/salary-details/:userId',
    exact: true,
    protected: true,
    element: <PayRollSalaryDetails />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/salary-structure/full-view',
    exact: true,
    protected: true,
    element: <SalaryStructureFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/employee-declaration',
    exact: true,
    protected: true,
    element: <EmployeeDeclarationFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/tax-declaration/form',
    exact: true,
    protected: true,
    element: <PayRollTaxDeclarationForm />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/salary-details/salary-breakup/:date',
    exact: true,
    protected: true,
    element: <PayRollSummary />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/salary-details/salary-breakup/:userId/:date',
    exact: true,
    protected: true,
    element: <PayRollSummary />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/reimbursement',
    exact: true,
    protected: true,
    element: <UserReimbursementFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/reimbursement/setting/category/:id',
    exact: true,
    protected: true,
    element: <ReimbursementCategoryDetail />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/reimbursement/setting/add-category',
    exact: true,
    protected: true,
    element: <ReimbursementAddCategory />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/reimbursement/setting/edit-category/:category_id',
    exact: true,
    protected: true,
    element: <ReimbursementEditCategory />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/reimbursement/form',
    exact: true,
    protected: true,
    element: <UserReimbursementForm />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/loan',
    exact: true,
    protected: true,
    element: <UserLoanFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/payroll/payroll-summary',
    exact: true,
    protected: true,
    element: <UserPayRollSummaryFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll',
    exact: true,
    protected: true,
    element: <PayrollAdminTabs />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/:componentId',
    exact: true,
    protected: true,
    element: <PayrollAdminTabs />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/:componentId/:subComponentId',
    exact: true,
    protected: true,
    element: <PayrollAdminTabs />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/payroll-summary',
    exact: true,
    protected: true,
    element: <PayrollSummary />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/employee-salary-manager',
    exact: true,
    protected: true,
    element: <EmployeeSalaryManager />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/processing',
    exact: true,
    protected: true,
    element: <AdminPayRollProcessing />,
    menuTitle: 'Payroll',
    children: [],
  },
  // EmplyeePANPendingList
  {
    path: '/admin/payroll/processing/emp-pan',
    exact: true,
    protected: true,
    element: <EmplyeePANPendingList />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/form24Q',
    exact: true,
    protected: true,
    element: <Form24Q />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/form24Q/challan',
    exact: true,
    protected: true,
    element: <ChallanForm />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/form24Q/challan/:id',
    exact: true,
    protected: true,
    element: <ChallanForm />,
    menuTitle: 'Payroll',
    children: [],
  },
  // {
  //     path: '/admin/payroll/form24Q/setting',
  //     exact: true,
  //     protected: true,
  //     element: <Form24Setting />,
  //     menuTitle: "Payroll",
  //     children: []
  // },
  //
  {
    path: '/admin/payroll/salary-configuration-form',
    exact: true,
    protected: true,
    element: <SalaryConfigurationForm />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/salary-configuration-form/:id/:action?',
    exact: true,
    protected: true,
    element: <SalaryConfigurationForm />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/loan',
    exact: true,
    protected: true,
    element: <LoanFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/advance-salary',
    exact: true,
    protected: true,
    element: <AdvanceSalaryFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/reimbursement',
    exact: true,
    protected: true,
    element: <ReimbursementFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/hold-salary',
    exact: true,
    protected: true,
    element: <HoldSalaryFullView />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/admin/payroll/employee-declaration/:id',
    exact: true,
    protected: true,
    element: <EmployeeDeclarationDetail />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/recruitment',
    exact: true,
    protected: true,
    element: <RecruitmentDashboard />,
    menuTitle: 'Recruitment',
    children: [],
  },
  {
    path: '/recruitment/refer-form/:id',
    exact: true,
    protected: true,
    element: <ReferForm />,
    menuTitle: 'Recruitment',
    children: [],
  },
  {
    path: '/recruitment/apply-form/:id',
    exact: true,
    protected: true,
    element: <ApplyForm />,
    menuTitle: 'Recruitment',
    children: [],
  },
  {
    path: '/recruitment/referral',
    exact: true,
    protected: true,
    element: <ReferralOpening />,
    menuTitle: 'Referral',
    children: [],
  },
  {
    path: '/recruitment/opening',
    exact: true,
    protected: true,
    element: <ReferralOpening />,
    menuTitle: 'Opening',
    children: [],
  },
  {
    path: '/recruitment/internal-job',
    exact: true,
    protected: true,
    element: <InternalJobOpening />,
    menuTitle: 'Opening',
    children: [],
  },
  {
    path: '/recruitment/requisition',
    exact: true,
    protected: true,
    element: <Requisition />,
    menuTitle: 'Requisition',
    children: [],
  },
  {
    path: '/recruitment/requisition-form',
    exact: true,
    protected: true,
    element: <RequisitionForm />,
    menuTitle: 'Requisition',
    children: [],
  },

  {
    path: '/recruitment/interview-schedule/feedback',
    exact: true,
    protected: true,
    element: <FeedBackForm />,
    menuTitle: 'Recruitment',
    children: [],
  },
  {
    path: '/admin/employee',
    exact: true,
    protected: true,
    element: <EmployeeList />,
    menuTitle: 'Employee List',
    children: [],
  },

  {
    path: '/admin/employee/add',
    exact: true,
    protected: true,
    element: <Employee />,
    menuTitle: 'Employee',
    children: [],
  },
  {
    path: '/admin/employee/edit/:id',
    exact: true,
    protected: true,
    element: <Employee />,
    menuTitle: 'Employee',
    children: [],
  },
  {
    path: '/user-profile',
    exact: true,
    protected: true,
    element: <UserProfile />,
    menuTitle: 'Profile',
    children: [],
  },
  {
    path: '/user-profile/:id',
    exact: true,
    protected: true,
    element: <UserProfile />,
    menuTitle: 'Profile',
    children: [],
  },
  {
    path: '/admin/employee/detail/:id',
    exact: true,
    protected: true,
    element: <UserProfile />,
    menuTitle: 'Profile',
    children: [],
  },
  {
    path: '/user-profile/personal-details/:id',
    exact: true,
    protected: true,
    element: <EditPersonalDetails />,
    menuTitle: 'Profile',
    children: [],
  },
  {
    path: '/user-profile/bank-details/:id',
    exact: true,
    protected: true,
    element: <EditBankDetails />,
    menuTitle: 'Profile',
    children: [],
  },
  {
    path: '/admin/employee/employee-details/:id',
    exact: true,
    protected: true,
    element: <EditEmployeeDetails />,
    menuTitle: 'Profile',
    children: [],
  },
  {
    path: '/admin/recruitment',
    exact: true,
    protected: true,
    element: <AdminJobList />,
    menuTitle: 'Latest Jobs',
    children: [],
  },

  {
    path: '/admin/recruitment/post-job',
    exact: true,
    protected: true,
    element: <PostJob />,
    menuTitle: 'Post a Job',
    children: [],
  },
  {
    path: '/admin/recruitment/post-job/:id',
    exact: true,
    protected: true,
    element: <PostJob />,
    menuTitle: 'Job Form',
    children: [],
  },
  {
    path: '/admin/recruitment/details/:id',
    exact: true,
    protected: true,
    element: <AdminJobDetails />,
    menuTitle: 'Job Details',
    children: [],
  },
  {
    path: '/admin/recruitment/details/:id/feedback',
    exact: true,
    protected: true,
    element: <ViewFeedBack />,
    menuTitle: 'Feedback',
    children: [],
  },
  {
    path: '/admin/org',
    exact: true,
    protected: true,
    element: <Org />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/add',
    exact: true,
    protected: true,
    element: <AddOrEditOrgMobile />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/parent/edit/:id',
    exact: true,
    protected: true,
    element: <AddOrEditOrgMobile />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/child/add',
    exact: true,
    protected: true,
    element: <AddOrEditOrgMobile />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/child/edit/:id',
    exact: true,
    protected: true,
    element: <AddOrEditOrgMobile />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/admin/add',
    exact: true,
    protected: true,
    element: <AddOrEditAdminMobile />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/admin/edit/:id',
    exact: true,
    protected: true,
    element: <AddOrEditAdminMobile />,
    menuTitle: 'Organization',
    children: [],
  },
  {
    path: '/admin/org/config/:id',
    exact: true,
    protected: true,
    element: <OrgConfigDashboard />,
    menuTitle: 'Organization',
    children: [],
  },
  // DocumentsDocuments
  {
    path: '/admin/docs',
    exact: true,
    protected: true,
    element: <DocumentsDashboard />,
    menuTitle: 'Documents',
    children: [],
  },
  {
    path: '/admin/docs/hr-letter',
    exact: true,
    protected: true,
    element: <HRLetterFullView />,
    menuTitle: 'Documents',
    children: [],
  },
  {
    path: '/admin/docs/policies',
    exact: true,
    protected: true,
    element: <PoliciesFullView />,
    menuTitle: 'Documents',
    children: [],
  },
  {
    path: '/admin/docs/add/policies',
    exact: true,
    protected: true,
    element: <DocumentPolicyForm />,
    menuTitle: 'Documents',
    children: [],
  },
  {
    path: '/admin/docs/add/hr-letter',
    exact: true,
    protected: true,
    element: <DocumentsHrLetterForm />,
    menuTitle: 'Documents',
    children: [],
  },
  {
    path: '/inbox/leave',
    exact: true,
    protected: true,
    element: <InboxDashboard />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/attendance',
    exact: true,
    protected: true,
    element: <InboxDashboard />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/attendance/detail/:type/:id',
    exact: true,
    protected: true,
    element: <AttendanceDetail />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/leave/detail/:type/:id',
    exact: true,
    protected: true,
    element: <LeaveDetail />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/documentation/detail/:type/:id',
    exact: true,
    protected: true,
    element: <DocsDetail />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/documentation',
    exact: true,
    protected: true,
    element: <InboxDashboard />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/documentation/:id',
    exact: true,
    protected: true,
    element: <InboxDashboard />,
    menuTitle: 'Inbox',
    children: [],
  },

  {
    path: '/inbox/resign',
    exact: true,
    protected: true,
    element: <InboxDashboard />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/resign/:id',
    exact: true,
    protected: true,
    element: <ResignationDetail />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/lms',
    exact: true,
    protected: true,
    element: <InboxDashboard />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/inbox/lms/detail/:id',
    exact: true,
    protected: true,
    element: <InboxLmsDetail />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/admin/recruitment/job-list/full-view',
    exact: true,
    protected: true,
    element: <AdminJobListFullView />,
    menuTitle: 'Latest Jobs',
    children: [],
  },
  {
    path: '/admin/recruitment/requisition/job-form/:id',
    exact: true,
    protected: true,
    element: <PostRequisitionToJob />,
    menuTitle: 'Requisition Job Form',
    children: [],
  },

  {
    path: '/admin/org/admins/full-view',
    exact: true,
    protected: true,
    element: <OrgAdminFullView />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/admin/recruitment/requisition/full-view',
    exact: true,
    protected: true,
    element: <RequisitionFullView />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/admin/payroll/reimbursement/:id',
    exact: true,
    protected: true,
    element: <ReimbursementDetail />,
    menuTitle: 'Payroll',
    children: [],
  },
  {
    path: '/recruitment/job-interview/full-view',
    exact: true,
    protected: true,
    element: <InterviewFullView />,
    menuTitle: 'Inbox',
    children: [],
  },
  {
    path: '/admin/recruitment/recruiter/full-view',
    exact: true,
    protected: true,
    element: <RecruiterFullView />,
    menuTitle: 'Inbox',
    children: [],
  },

  // #########################################################################################################
  // External Recruitment
  // #########################################################################################################

  // user routes for those user which are not part of the company (external user)
  {
    path: '/:company_name/careers/jobs',
    exact: true,
    protected: false,
    element: <UserJobList />,
    menuTitle: 'Latest Jobs',
    children: [],
  },
  {
    path: '/:company_name/careers/jobs/job-detail/:id',
    exact: true,
    protected: false,
    element: <ExternalJobDetailsForUser />,
    menuTitle: 'Latest Jobs',
    children: [],
  },
  // external recruitment routes
  {
    path: 'recruitment/external-recruitment/login',
    exact: true,
    protected: false,
    element: <ExternalRecruitmentLogin />,
    menuTitle: 'External Recruitment Login',
    children: [],
  },
  {
    path: '/recruitment/external-recruitment',
    exact: true,
    protected: false,
    element: <ExternalRecruitment />,
    menuTitle: 'External Recruitment',
    children: [],
  },
  {
    path: '/recruitment/external-recruitment/detail/:id',
    exact: true,
    protected: false,
    element: <JobDetail />,
    menuTitle: 'External Recruitment',
    children: [],
  },
  {
    path: '/recruitment/external-recruitment/detail/:id/feedback',
    exact: true,
    protected: false,
    element: <ViewFeedBack />,
    menuTitle: 'External Recruitment',
    children: [],
  },

  // PerformanceManagement

  {
    path: '/performance-management',
    exact: true,
    protected: true,
    element: <PerformanceTabs />,
    menuTitle: 'Performance Management',
    children: [],
  },
  {
    path: '/performance-management/goal',
    exact: true,
    protected: true,
    element: <Goal />,
    menuTitle: 'Performance Management',
    children: [],
  },
  {
    path: '/performance-management/goal-form',
    exact: true,
    protected: true,
    element: <GoalForm />,
    menuTitle: 'Performance Management',
    children: [],
  },

  // Tracking
  {
    path: '/tracking',
    exact: true,
    protected: true,
    element: <TrackingTabs />,
    menuTitle: 'Tracking',
    children: [],
  },
  {
    path: '/tracking/:id',
    exact: true,
    protected: true,
    element: <TrackingTabs />,
    menuTitle: 'Tracking',
    children: [],
  },
  {
    path: '/admin/tracking',
    exact: true,
    protected: true,
    element: <AdminTrackingTabs />,
    menuTitle: 'Tracking',
    children: [],
  },
  {
    path: '/tracking/addmeeting/:type',
    exact: true,
    protected: true,
    element: <AddMeeting />,
    menuTitle: 'Tracking',
  },
  {
    path: '/tracking/attendmeeting/:id',
    exact: true,
    protected: true,
    element: <AttendMeeting />,
    menuTitle: 'Tracking',
  },

  {
    path: '/tracking/editmeeting/:type/:id',
    exact: true,
    protected: true,
    element: <EditMetting />,
    menuTitle: 'Tracking',
  },
  // Timeline
  {
    path: '/timeline/:employement_number',
    exact: true,
    protected: true,
    element: <TimeLine />,
    menuTitle: 'TimeLine',
    children: [],
  },

  // google
  {
    path: '/google/:token',
    exact: true,
    protected: false,
    element: <Google />,
    menuTitle: 'Tracking',
    children: [],
  },

  // lms
  {
    path: '/lms',
    exact: true,
    protected: true,
    element: <LmsList />,
    menuTitle: 'Tracking',
    children: [],
  },
  {
    path: '/lms/instruction/:id',
    exact: true,
    protected: true,
    element: <LmsInstruction />,
    menuTitle: 'Tracking',
    children: [],
  },
  {
    path: '/lms/video/:id',
    exact: true,
    protected: true,
    element: <LmsVideo />,
    menuTitle: 'Tracking',
    children: [],
  },
  {
    path: '/lms/quiz/:id',
    exact: true,
    protected: true,
    element: <LmsQuiz />,
    menuTitle: 'Tracking',
    children: [],
  },

  // lms admin
  {
    path: '/admin/lms/list',
    exact: true,
    protected: true,
    element: <AdminLmsList />,
    menuTitle: 'Tracking',
    children: [],
  },

  {
    path: '/admin/lms/training-form',
    exact: true,
    protected: true,
    element: <TrainingForm />,
    menuTitle: 'Tracking',
    children: [],
  },
  // {
  //     path: '/lms/quiz/:id',
  //     exact: true,
  //     protected: true,
  //     element: <LmsQuiz />,
  //     menuTitle: "Tracking",
  //     children: []
  // },
  {
    path: '/admin/lms/details/:id',
    exact: true,
    protected: true,
    element: <LmsDetail />,
    menuTitle: 'Tracking',
    children: [],
  },

  // Feeds
  {
    path: '/feeds',
    exact: true,
    protected: true,
    element: <Feeds />,
    menuTitle: 'Feeds',
    children: [],
  },
  {
    path: '/feeds/add-post',
    exact: true,
    protected: true,
    element: <AddPost />,
    menuTitle: 'Feeds',
    children: [],
  },

  {
    path: '/admin/feeds',
    exact: true,
    protected: true,
    element: <FeedsTab />,
    menuTitle: 'Feeds',
    children: [],
  },
  {
    path: '/admin/feeds/edit-feed/:id',
    exact: true,
    protected: false,
    element: <EditFeed />,
    menuTitle: 'Feeds',
    children: [],
  },
  {
    path: '/admin/feeds/add-feed',
    exact: true,
    protected: true,
    element: <AddFeedPost />,
    menuTitle: 'Feeds',
    children: [],
  },

  {
    path: '/admin/feeds/display-post/:id',
    exact: true,
    protected: true,
    element: <DisplayPost />,
    menuTitle: 'Feeds',
    children: [],
  },

  {
    path: '*',
    exact: true,
    protected: false,
    element: <Navigate to="/app" />,
    children: [],
  },
];

export default routeList;
