import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    HRA: {
        January: {
            address: "",
            metro_city: null,
            declared_amount: null,
            owner_name: "",
            owner_pan_no: "",
            month: 1,
            attachment: "",
            file_name: ""
        },
        February: {
            address: "",
            metro_city: null,
            declared_amount: null,
            owner_name: "",
            owner_pan_no: "",
            month: 2,
            attachment: "",
            file_name: ""
        },
        March: {
            address: "",
            metro_city: null,
            declared_amount: null,
            owner_name: "",
            owner_pan_no: "",
            month: 3,
            attachment: "",
            file_name: ""
        },
        April: {
            address: "",
            metro_city: null,
            declared_amount: null,
            owner_name: "",
            owner_pan_no: "",
            month: 4,
            attachment: "",
            file_name: ""
        },
        May: {
            address: "",
            metro_city: null,
            declared_amount: null,
            owner_name: "",
            owner_pan_no: "",
            month: 5,
            attachment: "",
            file_name: ""
        },
        June: {
            address: "",
            metro_city: null,
            declared_amount: null,
            owner_name: "",
            owner_pan_no: "",
            month: 6,
            attachment: "",
            file_name: ""
        },
        July: {
            address: "",
            metro_city: null,
            declared_amount: null,
            owner_name: "",
            owner_pan_no: "",
            month: 7,
            attachment: "",
            file_name: ""
        },
        August: {
            address: "",
            metro_city: null,
            declared_amount: null,
            owner_name: "",
            owner_pan_no: "",
            month: 8,
            attachment: "",
            file_name: ""
        },
        September: {
            address: "",
            metro_city: null,
            declared_amount: null,
            owner_name: "",
            owner_pan_no: "",
            month: 9,
            attachment: "",
            file_name: ""
        },
        October: {
            address: "",
            metro_city: null,
            declared_amount: null,
            owner_name: "",
            owner_pan_no: "",
            month: 10,
            attachment: "",
            file_name: ""
        },
        November: {
            address: "",
            metro_city: null,
            declared_amount: null,
            owner_name: "",
            owner_pan_no: "",
            month: 11,
            attachment: "",
            file_name: ""
        },
        December: {
            address: "",
            metro_city: null,
            declared_amount: null,
            owner_name: "",
            owner_pan_no: "",
            month: 12,
            attachment: "",
            file_name: ""
        }
    },
    taxFreeAllowance: {

    },
    houseProperty: [],
    investmentDeclaration: [],
    mediclaimDeclaration: {
        section80DForSelfAndChildren: {
            section: "section80DForSelfAndChildren",
            choice: null,
            declared_amount: "",
            attachment: "",
            file_name: ""
        },
        section80DForParents: {
            section: "section80DForParents",
            choice: null,
            declared_amount: "",
            attachment: "",
            file_name: ""
        },
        section80DForSeniorParents: {
            section: "section80DForSeniorParents",
            choice: null,
            declared_amount: "",
            attachment: "",
            file_name: ""
        },
        section80DForSelfChildrenAndSeniorParent: {
            section: "section80DForSelfChildrenAndSeniorParent",
            choice: null,
            declared_amount: "",
            attachment: "",
            file_name: ""
        }
    },
    donation: [],
    otherDecalaration: {
        choice: null,
        declared_amount: "",
        attachment: "",
        file_name: ""
    },
    isWindowOpen: false,
}
const taxDeclarationSlice = createSlice({
    name: 'taxDeclaration',
    initialState,
    reducers: {

        setHRADetails: (state, action) => {
            const {
                address,
                metro_city,
                declared_amount,
                owner_name,
                owner_pan_no,
                month,
                attachment,
                monthName,
                file_name
            } = action.payload;

            state.HRA[monthName].address = address;
            state.HRA[monthName].metro_city = metro_city !== undefined ? metro_city : null;
            state.HRA[monthName].declared_amount = declared_amount;
            state.HRA[monthName].owner_name = owner_name;
            state.HRA[monthName].owner_pan_no = owner_pan_no;
            state.HRA[monthName].month = month;
            state.HRA[monthName].attachment = attachment;
            state.HRA[monthName].file_name = file_name
        },

        setTaxFreeDeclarationState: (state, action) => {
            state.taxFreeAllowance = action.payload
        },

        setTaxFreeDeclaration: (state, action) => {
            const {
                id,
                declared_amount,
                attachment,
                file_name
            } = action.payload;

            state.taxFreeAllowance[id].declared_amount = declared_amount;
            state.taxFreeAllowance[id].attachment = attachment;
            state.taxFreeAllowance[id].file_name = file_name
        },

        setHouseProperty: (state, action) => {
            state.houseProperty = action.payload
        },

        setInvestmentDeclaration: (state, action) => {
            state.investmentDeclaration = action.payload
        },


        setMediclaimDeclarationSection80DForSelf: (state, action) => {
            const {
                choice,
                declared_amount,
                attachment,
                file_name
            } = action.payload;

            state.mediclaimDeclaration.section80DForSelfAndChildren.choice = choice;
            state.mediclaimDeclaration.section80DForSelfAndChildren.declared_amount = declared_amount;
            state.mediclaimDeclaration.section80DForSelfAndChildren.attachment = attachment;
            state.mediclaimDeclaration.section80DForSelfAndChildren.file_name = file_name
        },

        setMediclaimDeclarationSection80DForParents: (state, action) => {
            const {
                choice,
                declared_amount,
                attachment,
                file_name
            } = action.payload;

            state.mediclaimDeclaration.section80DForParents.choice = choice;
            state.mediclaimDeclaration.section80DForParents.declared_amount = declared_amount;
            state.mediclaimDeclaration.section80DForParents.attachment = attachment;
            state.mediclaimDeclaration.section80DForParents.file_name = file_name
        },
        setMediclaimDeclarationSection80DForSeniorParents: (state, action) => {
            const {
                choice,
                declared_amount,
                attachment,
                file_name
            } = action.payload;

            state.mediclaimDeclaration.section80DForSeniorParents.choice = choice;
            state.mediclaimDeclaration.section80DForSeniorParents.declared_amount = declared_amount;
            state.mediclaimDeclaration.section80DForSeniorParents.attachment = attachment;
            state.mediclaimDeclaration.section80DForSeniorParents.file_name = file_name
        },
        setMediclaimDeclarationSection80DForSelfChildrenAndSeniorParent: (state, action) => {
            const {
                choice,
                declared_amount,
                attachment,
                file_name
            } = action.payload;

            state.mediclaimDeclaration.section80DForSelfChildrenAndSeniorParent.choice = choice;
            state.mediclaimDeclaration.section80DForSelfChildrenAndSeniorParent.declared_amount = declared_amount;
            state.mediclaimDeclaration.section80DForSelfChildrenAndSeniorParent.attachment = attachment;
            state.mediclaimDeclaration.section80DForSelfChildrenAndSeniorParent.file_name = file_name
        },

        setDonation: (state, action) => {

            state.donation = action.payload
        },

        setOtherDecalaration: (state, action) => {
            console.log(action.payload)
            const {
                choice,
                declared_amount,
                attachment,
                file_name
            } = action.payload;

            state.otherDecalaration.choice = choice;
            state.otherDecalaration.declared_amount = declared_amount;
            state.otherDecalaration.attachment = attachment;
            state.otherDecalaration.file_name = file_name
        },

        setIsWindowOpen: (state, action) => {
            state.isWindowOpen = action.payload
        }
    },
})
export const {
    setHRADetails,
    setTaxFreeDeclarationState,
    setTaxFreeDeclaration,
    setHouseProperty,
    setInvestmentDeclaration,
    setMediclaimDeclarationSection80DForSelf,
    setMediclaimDeclarationSection80DForParents,
    setMediclaimDeclarationSection80DForSeniorParents,
    setMediclaimDeclarationSection80DForSelfChildrenAndSeniorParent,
    setDonation,
    setOtherDecalaration,
    setIsWindowOpen
} = taxDeclarationSlice.actions;

export default taxDeclarationSlice.reducer;