import dashboardImg from "../assets/dashboard.svg";
import orgImg from "../assets/Org.svg";
import employeeImg from "../assets/employee.svg";
import leaveImg from "../assets/leave.svg";
import attendanceImg from "../assets/attendance.svg";
import payrollImg from "../assets/payroll.svg";
import profileImg from "../assets/profile.svg";
import settingImg from "../assets/setting.svg";
import jobImg from "../assets/job.svg";
import activeInbox from "../assets/activeInbox.svg";
import performanceManagement from "../assets/performanceManagement.png";
import tracking from "../assets/tracking.png";
import lms from "../assets/inactiveLms.png";
import feed from "../assets/feed.png";

import activeDashboardImg from "../assets/activeDashboardImg.svg";
import activeAttendanceImg from "../assets/activeAttendanceImg.svg";
import activeLeaveImg from "../assets/activeLeaveImg.svg";
import activeEmployeeImg from "../assets/activeEmployeeImg.svg";
import activeJobImg from "../assets/activeJobImg.svg";
import activePayrollImg from "../assets/activePayrollImg.svg";
import activePerformanceManagement from "../assets/activePerformanceManagement.png";
import activeTracking from "../assets/location.svg";
import activeLms from "../assets/activeLms.png";
import activeFeed from "../assets/activeFeed.png";

const menuList = [
  {
    key: "dashboard",
    image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/dashboard.svg",
    activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeDashboardImg.svg",
    label: "Dashboard",
    path: "/dashboard",
  },
  {
    key: "recruitment",
    image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/job.svg",
    activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeJobImg.svg",
    label: "Job",
    path: "/recruitment",
  },
  {
    key: "leave",
    image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/leave.svg",
    activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeLeaveImg.svg",
    label: "Leave",
    path: "/leave",
  },
  {
    key: "attendance",
    image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/attendance.svg",
    activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeAttendanceImg.svg",
    label: "Attendance",
    path: "/attendance",
  },
  {
    key: "payroll",
    image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/payroll.svg",
    activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activePayrollImg.svg",
    label: "Payroll",
    path: "/payroll",
  },
  {
    key: "inbox",
    image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/profile.svg",
    activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeInbox.svg",
    label: "Inbox",
    path: "/inbox/leave",
  },
  {
    key: "lms",
    image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/inactiveLms.png",
    activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeLms.png",
    label: "LMS",
    path: "/lms",
  },
  // {
  //     key: "performance",
  //     image: performanceManagement,
  //     activeImage: activePerformanceManagement,
  //     label: "Performance Management",
  //     path: "/performance-management"
  // },
  {
    key: "tracking",
    image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/tracking.png",
    activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/location.svg",
    label: "Tracking",
    path: "/tracking",
  },
  {
    key: "feeds",
    image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/feed.png",
    activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeFeed.png",
    label: "Feed",
    path: "/feeds",
  },
];

export default menuList;
