import home from "../assets/MobileMenu/home.png";
import homeActive from "../assets/MobileMenu/homeActive.png";
import attendance from "../assets/MobileMenu/attendance.png";
import attendanceActive from "../assets/MobileMenu/attendanceActive.png";
// import profile from "../assets/MobileMenu/profile.png";
// import profileActive from "../assets/MobileMenu/profileActive.png";
import more from "../assets/MobileMenu/more.png";
import moreActive from "../assets/MobileMenu/moreActive.png";
import activeLeaveImg from "../assets/MobileMenu/leaveActive.png";
import leave from "../assets/MobileMenu/leave.png";


const adminMobileMenuList = [
    {
        key: "home",
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/home.png",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/homeActive.png",
        label: "Home",
        path: "/admin/dashboard"
    },
    {
        key: "attendance",
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/attendance.png",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/attendanceActive.png",
        label: "Attendance",
        path: "/admin/attendance"
    },
    {
        key: "leave",
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/leave.png",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/leaveActive.png",
        label: "Leave",
        path: "/admin/leave"
    },
    {
        key: "more",
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/more.png",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/MobileMenu/moreActive.png",
        label: "More",
        path: ""
    },
]


export default adminMobileMenuList;