import './App.css';
import RouteComp from './routes/index'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./styles/_global.scss";
import "./styles/_mixins.scss";
function App() {


  return (
    <div>
      <RouteComp />
      <ToastContainer />
    </div>
  );
}

export default App;
